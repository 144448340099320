import React, { useLayoutEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import usePopup from "../../hooks/popup/usePopup";

import Loading from '../../components/iconify/loading';
import KakaoLogo from '../../assets/icon/kakao_logo.png';

import { customerSocialLogin, customerSocialRegistration } from '../../api/auth/AuthApi';
import { CustomerResponse } from '../../dto/ResponseData'
import { SocialRegistrationParams } from '../../dto/RequestParams'
import { AxiosResponse } from "axios";

export default function KakaoLogin() {

    const { showPopup } = usePopup();
    const navigate = useNavigate();
    const location = useLocation();

    const getUserInfo = async (code: string, state: string) => {
        customerSocialLogin({
            type: "kakao",
            code: code,
            state: state
        },
            (response) => {
                if (response) {
                    if (response) {
                        const data: CustomerResponse = response.data;
                        if (data.access_token) {
                            sessionStorage.setItem('customer_id', data.id);
                            sessionStorage.setItem('service_id', data.service_id);
                            sessionStorage.setItem('service_name', data.service_name);
                            sessionStorage.setItem('customer', JSON.stringify(data));
                            sessionStorage.setItem('access_token', data.access_token);
                            sessionStorage.setItem('account_type', "kakao");
                            sessionStorage.setItem('user_email', data.email);
                            sessionStorage.setItem('service_type', state);
                            navigate('/pdf_chat');
                            window.location.reload();
                        }
                        else {
                            onRegistration(data.email, "kakao");
                        }
                    }
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            }
        )
    }

    const onRegistration = (email: string, type: string) => {

        const registrationParams: SocialRegistrationParams = {
            name: email,
            email: email,
            type: type
        }
        customerSocialRegistration(registrationParams,
            (response: AxiosResponse<CustomerResponse, any> | undefined) => {
                if (response) {
                    const data: CustomerResponse = response.data;
                    if (data.access_token) {
                        sessionStorage.setItem('customer_id', data.id);
                        sessionStorage.setItem('service_id', data.service_id);
                        sessionStorage.setItem('service_name', data.service_name);
                        sessionStorage.setItem('customer', JSON.stringify(data));
                        sessionStorage.setItem('access_token', data.access_token);
                        sessionStorage.setItem('account_type', "google");
                        sessionStorage.setItem('user_email', data.email);
                        sessionStorage.setItem('service_type', 'pdf_chat');
                        navigate('/pdf_chat');
                        window.location.reload();
                    }
                    else {
                        showPopup({ message: `에러가 발생 했습니다.` });
                    }
                }
            },
            (status, detail) => {
                if (detail) {
                    showPopup({ message: detail.message });
                }
                else {
                    showPopup({ message: `Error(${status})\n실패 했습니다.` });
                }
            });
    }
    useLayoutEffect(() => {
        let code = new URL(window.location.href).searchParams.get("code");
        let state = new URL(window.location.href).searchParams.get("state");
        if (code && state) {
            getUserInfo(code, state)
        }
    }, []);

    return (
        <div className="login">
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <div style={{ fontSize: "40px" }}>로그인을 진행 중입니다.</div>
                    <div style={{ fontSize: "30px", marginTop: "60px", display: "flex", alignContent: "center", justifyContent: "center", gap: "15px" }}><img src={KakaoLogo} className="social_login_icon" />카카오 로그인</div>
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
                        <Loading width='80px' />
                    </div>
                </div>
            </div>
       </div>
    )

}