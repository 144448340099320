import { format } from 'date-fns';

export function gridDateTime(date: Date, newFormat?: string) {
    const fm = newFormat || 'yyyy-MM-dd HH:mm:ss';
    return date ? format(date, fm) : '';
}


export function chatDateTime(date: string, newFormat?: string) {
    const fm = newFormat || 'dd MMM yyyy p';
    return date ? format(new Date(date), fm) : '';
}

export const convertDate = (date: Date | null, isStart: boolean): Date | null => {
    if (!date) return null;
    let dateStr = gridDateTime(date, 'yyyy-MM-dd' + (isStart ? ' 00:00:00' : ' 23:59:59'))
    return new Date(dateStr);
}
