import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import usePopup from "../../hooks/popup/usePopup";
import '../pdfchat.css'
import { ReactComponent as Logout } from '../../assets/pdf_chat/logout.svg';
import { ReactComponent as Icon } from '../../assets/pdf_chat/logo.svg';
import { ReactComponent as Bell } from '../../assets/pdf_chat/bell_pin.svg';

import {
    requestCharge,
    getRequestChargeList,
    getBalance
} from '../../api/payment/PaymentApi';

import { RequestChargeItem } from "../../dto/Items";
import RequestApprovePopup from "../../popup/point/RequestApprovePopup";

interface TopProps {
    balance: string;
    requestStatus: number;
}

export default function Top(props: TopProps) {
    const { showPopup } = usePopup();
    const navigate = useNavigate();
    const customer_id = sessionStorage.getItem('customer_id');
    const service_id = sessionStorage.getItem('service_id');
    const isAdmin = service_id === process.env.REACT_APP_GUIDE_SERVICE_ID

    const [isRequestListOpen, setIsRequestListOpen] = useState<boolean>(false);
    const [isPointPopupShow, setIsPointPopupShow] = useState<boolean>(false);
    const [requestId, setRequestId] = useState<number>(-1);
    const [requestList, setRequestList] = useState<RequestChargeItem[]>();

    const onLogout = () => {
        sessionStorage.removeItem('customer_id');
        sessionStorage.removeItem('service_id');
        sessionStorage.removeItem('service_name');
        sessionStorage.removeItem('customer');
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('account_type');
        sessionStorage.removeItem('user_email');
        navigate('/pdf_chat_login');
        window.location.reload();
    }

    const handleRequestCharge = () => {
        showPopup({
            type: 'confirm',
            title: '확인',
            message: '포인트 충전을 요청 하시겠습니까?',
            confirmText: "확인",
            cancelText: "취소",
            confirm: () => {
                requestCharge({ customer_id: customer_id! },
                    (response) => {
                        showPopup({ message: "충전 요청을 했습니다." });
                    },
                    (status, detail) => {
                        showPopup({ message: detail ? detail.message : `Error(${status})\n실패 했습니다.` });
                    }
                );
            },
        });
    }

    const getRequestList = () => {
        getRequestChargeList({
            page: 1,
            limit: 1000,
            search: {
                period: {
                    start: "",
                    end: ""
                },
                category: "1"
            }
        },
            (response) => {
                if (response) {
                    setRequestList(response.data.list);
                }
            },
            (status, detail) => {
                console.log("==================== Fail");
            }
        );
    }

    const onRequestPopupClose = (isApprove: boolean) => {
        setIsPointPopupShow(false);
        if (isApprove) {
            showPopup({ message: "반영 했습니다." });
        }
    }

    useEffect(() => {
        if (isAdmin) {
            getRequestList();
        }
    }, [])
    return (
        <div className="top_layer">
            <div className="top_contents">
                <div className='top_logo'><Icon className="top_icon" />&nbsp;&nbsp;DreamChat</div>
                <div className="top_right">
                    {
                        isAdmin && requestList && requestList.length > 0 &&
                        <div className='top_logout' onClick={() => { }}>
                            <Bell className="top_logout_icon" onClick={() => { setIsRequestListOpen(prevState => !prevState) }} />
                            {
                                isRequestListOpen && <div className="request_list">
                                    {
                                        requestList.map((item, index) => {
                                            return (
                                                <div className="request_item" key={"request_item_" + index}>
                                                    <div>{item.email}</div>|<div>{item.balance}</div>|
                                                    <div style={{ padding: "6px 10px", background: "#426AE6", borderRadius: "3px", color: "#FFFFFF" }} onClick={() => { setRequestId(item.id); setIsPointPopupShow(true); }}>관리</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </div>
                    }
                    <div className="top_balance">
                        <div className="top_balance_text"><span style={{ color: "#6b8be2" }}>{props.balance}</span>&nbsp;point</div>
                        <div className="top_request_button" onClick={handleRequestCharge}>충전하기</div>
                    </div>
                    <div className='top_logout' onClick={onLogout}>
                        <Logout className="top_logout_icon" />
                    </div>
                </div>
            </div>
            {
                isPointPopupShow &&
                <RequestApprovePopup isShow={true} requestId={requestId} onClose={onRequestPopupClose} />
            }
        </div>
    )
}