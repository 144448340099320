import { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown'
// import { ReactComponent as SendIcon } from '../../assets/icon/send.svg';
import { ReactComponent as SendIcon } from '../../assets/pdf_chat/send.svg';
import { ReactComponent as ResultIcon } from '../../assets/pdf_chat/result.svg';
import { ReactComponent as CopyIcon } from '../../assets/pdf_chat/copy.svg';

import { ReactComponent as BotIcon } from '../../assets/pdf_chat/bot.svg';
import { ReactComponent as UserIcon } from '../../assets/pdf_chat/profile.svg';

import Loading from '../../components/iconify/loading';
import { sendFileChatMessage } from '../../api/chatbot/ChatbotApi'
import { getKnowledgePdfFileSummary } from '../../api/product/KnowledgeFileApi';
import usePopup from "../../hooks/popup/usePopup";
import { copyToClipboard } from '../../utils/Clipboard';
import { ConversationKnowledgeItem, SessionConversationItem } from '../../dto/Items';
import {
    getSessionConversation,
} from '../../api/analytics/ChatLogApi';
import { KnowledgeItem } from '../../dto/Items';

import '../pdfchat.css'
import { FileManager } from '../manager/FileManager';

interface ChatMessage {
    message: string;
    isUser: boolean;
    time: Date;
    isloading?: boolean;
    id?: number;
    knowledge?: ConversationKnowledgeItem[];
}

interface FileChatProps {
    knowledge_set_id: number;
    selected_file_status: number;
    isReferenceOpen: boolean;
    makedSummary: string | undefined;
    onKnowledgeClick: (knowledge?: ConversationKnowledgeItem[]) => void;
    onPointChange: () => void;
}

function FileChat({ knowledge_set_id, selected_file_status, onKnowledgeClick, onPointChange, isReferenceOpen, makedSummary }: FileChatProps) {
    const service_id = sessionStorage.getItem('service_id');
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const userName = customer.name;
    const { showPopup } = usePopup();
    const fileManager = FileManager.getInstance();

    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState('');
    const [selectedChat, setSelectedChat] = useState<number | undefined>();
    const [inputStatus, setInputStatus] = useState(true);
    const [summary, setSummary] = useState<string>("");

    const [isInputFocus, setIsInputFocus] = useState(false);

    const scrollRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const addMessage = (message: string, isUser: boolean, time: Date) => {
        if (isUser) setMessage('');
        setMessageList(prevState => [...prevState, { message: message, isUser: isUser, time: time }, { message: "응답을 처리 중입니다.", isUser: false, isloading: true, time: new Date() }]);
    }

    const addResultMessage = (userMessage: string, id: string, userTime: Date, botMessage: string, knowledge?: any[]) => {
        let convertedKnowledge: ConversationKnowledgeItem[] | undefined = undefined;
        if (knowledge) {
            convertedKnowledge = [];
            for (let item of knowledge) {
                convertedKnowledge.push({
                    content: item.description as string,
                    file_name: item.link.metadata.source as string,
                    metadata: item.link.metadata,
                    tree_id: item.link.tree_id as number
                });
            }
        }

        setMessageList(prevState => {
            let temp = prevState;
            temp.splice(prevState.length - 2, 2)
            return [...temp, { message: userMessage, isUser: true, time: userTime }, { message: botMessage, id: +id, isUser: false, isloading: false, time: new Date(), knowledge: convertedKnowledge }]
        });
    }
    const setMessages = (messages: SessionConversationItem[]) => {
        let temp = [];
        for (let item of messages) {
            temp.push({ message: item.question, isUser: true, time: new Date(item.startedAt) })
            temp.push({ message: item.answer, isUser: false, id: item.id, knowledge: item.knowledge, isloading: false, time: new Date(item.endedAt) })
        }
        setMessageList(temp);
    }

    const sendChatBotMessage = async (question: string | null) => {
        if (!question) return;
        const utterance = `${question}`
        let userTime = new Date();
        if (!inputStatus || utterance.length === 0) return;
        setInputStatus(false)
        addMessage(utterance, true, userTime);
        scrollToBottom();
        sendFileChatMessage(
            {
                utterance: utterance,
                user_name: userName!,
                service_id: service_id!,
                knowledge_set_id: String(knowledge_set_id), // TODO
                session_id: fileManager.getSelectedFile()?.session_id
            },
            (response) => {
                if (response) {
                    const returnText = response.data?.template?.outputs[0]?.simpleText?.text || '';
                    const knowledgeList = response.data?.template?.outputs[1].listCard?.items
                    let currentSessionId = sessionStorage.getItem("currentSessionId");
                    if (!currentSessionId) {
                        sessionStorage.setItem("currentSessionId", response.data.session_id)
                    }
                    if (!currentSessionId || currentSessionId === response.data.session_id) {
                        addResultMessage(utterance, response.data.answer_id, userTime, returnText, knowledgeList);
                        inputRef.current?.focus();
                        scrollToBottom();
                        sessionStorage.setItem('sessionId', response.data.session_id);
                    }
                    setInputStatus(true);
                    onPointChange();
                }
            }
            ,
            (status) => {
                addResultMessage(utterance, "-1", userTime, "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
                inputRef.current?.focus();
                scrollToBottom();
                setInputStatus(true)
                console.log("=========================== FAIL : " + status)
            }
        )
        inputRef.current?.focus();
    }

    const KnowledgeLink = ({ id, knowledge }: { id?: number, knowledge?: ConversationKnowledgeItem[] }) => {
        let knowledgeArray: ConversationKnowledgeItem[][] = [];
        if (knowledge && knowledge.length > 0) {
            const grouped = knowledge.reduce((acc, item) => {
                (acc[item.tree_id] = acc[item.tree_id] || []).push(item);
                return acc;
            }, {} as { [key: string]: ConversationKnowledgeItem[] });
            Object.keys(grouped).sort().forEach((key, index) => {
                grouped[key].forEach(item => (item.tree_id = index));
                knowledgeArray.push(grouped[key]);
            });
        }
        return (
            knowledgeArray.length > 0 ?
                <div className='pdf_knowledge_item_layout'>
                    <div className='pdf_knowledge' onClick={() => { setSelectedChat(id); onKnowledgeClick(knowledge); }}>
                        <ResultIcon style={{ width: '20px', height: '20px' }} />&nbsp;출처
                    </div>
                    {
                        knowledgeArray.map((item, index) => {
                            return (
                                <div className={'pdf_knowledge_item ' + 'item_' + (index + 1)} onClick={() => { setSelectedChat(id); onKnowledgeClick(item); }}>
                                    {index + 1}
                                </div>
                            )
                        })
                    }

                </div>
                :
                <></>
        )
    }

    const KnowledgeCopy = ({ text }: { text: string }) => {
        return (
            <div className='pdf_knowledge' onClick={() => { copyTo(text); }}><CopyIcon style={{ width: '20px', height: '20px' }} />&nbsp;복사</div>
        )
    }

    const copyTo = async (text: string) => {
        copyToClipboard(text).then((result) => {
            if (result) {
                showPopup({ message: '복사 되었습니다.' });
            }
            else {
                showPopup({ message: '복사에 실패 했습니다.' });
            }
        })
    }

    const Bot = ({ text, time, id, knowledge, isloading }: { text: string, time: Date, id?: number, knowledge?: ConversationKnowledgeItem[], isloading: boolean | undefined }) => {
        return (
            <div>
                <div className='pdf_bot_text'>
                    <div className='bot_icon_layout'>
                        <BotIcon className='bot_icon' />&nbsp;&nbsp;DreamChat Bot
                    </div>
                    {
                        isloading ?
                            <div className='textbox' style={{ display: 'flex' }} ><div><ReactMarkdown>{text}</ReactMarkdown></div><Loading width='20px' /></div>
                            :
                            <>
                                <div className='textbox'><ReactMarkdown>{text}</ReactMarkdown></div>
                                <div className='pdf_knowledge_layout'>
                                    <KnowledgeLink id={id} knowledge={knowledge} />
                                    <KnowledgeCopy text={text} />
                                </div>
                            </>
                    }
                </div>
                {/* <div className='bot chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div> */}
            </div>
        );
    }

    const User = ({ text, time }: { text: string, time: Date }) => {
        return (
            <div>
                <div className='user_icon_layout'>
                    <UserIcon className='user_icon' />&nbsp;나
                </div>
                <div className='pdf_user_text'>
                    <div className='textbox'>{text}</div>
                </div>
                {/* <div className='user chat_time'>{gridDateTime(time, 'yyyy-MM-dd hh:mm:ss')}</div> */}
            </div>
        );
    }

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => { scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight; }, 50);
        }
    };
    const getConversation = (summary: string) => {
        setMessageList([]);
        onKnowledgeClick([]);
        const session_id = fileManager.getSelectedFile()?.session_id;
        if (session_id) {
            getSessionConversation({
                id: session_id,
            }, (response) => {
                if (response) {
                    setMessages(response.data);
                    setSummary(summary);
                    scrollToBottom();
                }
                else {
                    setSummary(summary);
                }
            }, (status, detail) => {
                // showPopup({ message: detail? detail.message :  `Error(${status})\n실패 했습니다.` });
            });
        }
    }

    const getSummary = () => {
        const selected = fileManager.getSelectedFile();
        if (selected) {
            getKnowledgePdfFileSummary({ file_id: selected.id },
                (response) => {
                    if (response) {
                        getConversation(response.data);
                    }
                    else {
                        getConversation("");
                    }
                },
                () => { }
            )
        }
    }

    let animationTag = 0;
    let timer = useRef<NodeJS.Timeout | undefined>();

    const animatedSummary = (text: string | undefined) => {
        animationTag++;
        if (text && animationTag < text.length) {
            setSummary(text.substring(0, animationTag));
            timer.current = setTimeout(() => { animatedSummary(text) }, 20);
        }
        else {
            clearTimeout(timer.current);
        }
    }

    useEffect(() => {
        clearTimeout(timer.current);
        animationTag = 0;
        getSummary();
    }, [knowledge_set_id])

    useEffect(() => {
        setInputStatus(selected_file_status === 0);
        if (selected_file_status === 0 && makedSummary && makedSummary.length > 0) {
            clearTimeout(timer.current);
            setSummary(makedSummary);
        }
    }, [selected_file_status]);

    useEffect(() => {
        if (makedSummary && makedSummary.length > 0 && selected_file_status !== 0) {
            animatedSummary(makedSummary);
        }
        return () => { clearTimeout(timer.current) }
    }, [makedSummary]);

    useEffect(() => {
        if (!isReferenceOpen) {
            setSelectedChat(undefined);
        }
    }, [isReferenceOpen])

    useEffect(() => {
        const container = document.querySelector("#summary_layout");
        const tempList = container?.querySelectorAll("ol");
        const example = tempList && tempList[tempList.length -1];
        const items = example?.querySelectorAll("li");
        if (items) {
            for (let i = 0; i < items.length; i++) {
                items[i].addEventListener('click', sugestClickEvent);
            }
        }
        return releaseClickEvent;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summary]);

    const sugestClickEvent = (event: MouseEvent) => {
        if (event.target instanceof Element) {
            sendChatBotMessage(event.target.textContent);
        }
    }

    const activeEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            sendChatBotMessage(message);
        }
    }

    // const sugestClickEvent = (text: string) => {
    //     sendChatBotMessage(text);
    // }

    const releaseClickEvent = () => {
        const container = document.querySelector("#summary_layout");
        const items = container?.querySelectorAll("li");
        if (items) {
            for (let i = 0; i < items.length; i++) {
                items[i].removeEventListener('click', sugestClickEvent);
            }
        }

    }

    const getStatueString = () => {
        switch (selected_file_status) {
            case 0:
                return "응답을 생성 중입니다.";
            case 1:
            case 2:
            case 3:
                return "문서를 분석 중입니다.";
            case 999:
                return "오류가 발생 했습니다.";
        }
    }

    const getGuideString = () => {
        switch (selected_file_status) {
            case 0:
                return "하단 입력창을 이용해 질문을 해 보세요.";
            case 1:
            case 2:
            case 3:
                return "문서를 분석 중입니다.";
            case 999:
                return "오류가 발생 했습니다.";
        }
    }

    return (
        <div className='file_chat_content' >
            <div className='file_chat_layout' ref={scrollRef}>
                <div className='pdf_file_info'>
                    <div>
                        현재 파일 : <span className='pdf_file_name'>&nbsp;{fileManager.getSelectedFile()?.name || ""}</span>
                    </div>
                    <br />
                    <div>
                        내용 요약 : <span className='pdf_summary' id={"summary_layout"}>
                            &nbsp;<ReactMarkdown>{summary}</ReactMarkdown>
                            {
                                selected_file_status !== 0 && <Loading width={''} />
                            }
                        </span>
                    </div>
                </div>
                {
                    messageList && messageList.length > 0 ?
                        <>
                            {
                                messageList.map((data, index) => (
                                    <div key={'chat_item_' + index} style={{ background: data.id !== undefined && data.id === selectedChat ? "#fdf0d5" : "#FFFFFF" }}>
                                        {
                                            data.isUser ?
                                                <User text={data.message} time={data.time} />
                                                :
                                                <Bot text={data.message} time={data.time} id={data.id} knowledge={data.knowledge} isloading={data.isloading} />
                                        }
                                    </div>
                                ))
                            }
                        </>
                        :
                        <>
                            <div className='pdf_chat_guide'>
                                {getGuideString()}
                            </div>
                        </>
                }
            </div>
            <div className={`pdf_chat_input_box${isInputFocus ? ' select' : ''}`}>
                <input className={`pdf_chat_input`} ref={inputRef}
                    placeholder={inputStatus ? '문서에 관해서 무엇이든 물어보세요.' : getStatueString()}
                    disabled={!inputStatus} value={message}
                    onFocus={() => { setIsInputFocus(true) }}
                    onBlur={() => { setIsInputFocus(false) }}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => activeEnter(e)} />
                <div className='pdf_send_box'
                    onClick={() => sendChatBotMessage(message)}>
                    <SendIcon className='pdf_send_button' />
                </div>
            </div>
        </div>
    );
}
export default FileChat;