import { useState, useRef, useEffect } from 'react';
import Markdown from 'react-markdown'
import { ReactComponent as Logo } from '../../assets/pdf_chat/logo.svg';
import { ReactComponent as Close } from '../../assets/pdf_chat/close_2.svg';
import { ReactComponent as SendIcon } from '../../assets/pdf_chat/send.svg';
import { ReactComponent as BotIcon } from '../../assets/pdf_chat/bot.svg';

import { sendChatMessage, sendFileChatMessage, closeCatbotSession, ChatMessageResponse } from '../../api/chatbot/ChatbotApi'

import { gridDateTime } from '../../utils/TimeFomatter';
import "../../pdfchat/pdfchat.css"
import { AxiosResponse } from 'axios';

interface ChatMessage {
    message: string;
    isUser: boolean;
    time: Date;
    isloading?: boolean;
}

export default function PdfChatView() {

    const params = new URLSearchParams(window.location.search);
    const serviceId = params.get("service_id");
    const knowledge_set_id = params.get("knowledge_set_id");
    let userName = params.get("user_name");
    if (!userName) {
        userName = crypto.randomUUID();
    }

    const [isChatShow, setIsChatShow] = useState(false);

    const [inputStatus, setInputStatus] = useState(true);
    const [messageList, setMessageList] = useState<ChatMessage[]>([]);
    const [message, setMessage] = useState('');

    const inputRef = useRef<HTMLInputElement>(null);

    const scrollRef = useRef<HTMLDivElement>(null);

    const addMessage = (message: string, isUser: boolean, time: Date) => {
        let temp = [...messageList];
        temp.push({ message: message, isUser: isUser, time: time })
        temp.push({ message: "", isUser: false, isloading: true, time: new Date() })
        if (isUser) setMessage('');
        setMessageList(temp);
    }

    const addResultMessage = (userMessage: string, userTime: Date, botMessage: string) => {
        let temp = [...messageList];
        temp.push({ message: userMessage, isUser: true, time: userTime })
        temp.push({ message: botMessage, isUser: false, isloading: false, time: new Date() })
        setMessageList(temp);
    }

    const activeEnter = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            sendChatBotMessage();
        }
    }

    const scrollToBottom = () => {
        if (scrollRef.current) {
            setTimeout(() => { scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight; }, 200);
        }
    };

    const closeSession = async () => {
        let sessionId = sessionStorage.getItem('sessionId');
        if (sessionId && sessionId.length > 0) {
            await closeCatbotSession({ service_id: serviceId!, session_id: sessionId }, () => {
                sessionStorage.removeItem('sessionId');
            },
                () => {
                    sessionStorage.removeItem('sessionId');
                })
        }
        setMessageList([]);
    }

    const sendChatBotMessage = async () => {
        if (!inputStatus) return;
        const utterance = `${message}`;
        let userTime = new Date();
        setInputStatus(false)
        addMessage(utterance, true, userTime);
        scrollToBottom();
        if (knowledge_set_id) {
            sendPdfChatMessage(utterance, (response) => { chatSuccess(utterance, userTime, response) }, (status, detail) => { chatFail(utterance, userTime, status, detail) });
        }
        else {
            sendNormalChatMessage(utterance, (response) => { chatSuccess(utterance, userTime, response) }, (status, detail) => { chatFail(utterance, userTime, status, detail) });
        }
        inputRef.current?.focus();
    }

    const sendNormalChatMessage = async (quastion: string, onSuccess: (response: AxiosResponse<ChatMessageResponse, any> | undefined) => void, onFail: (status: number, detail: string) => void) => {
        await sendChatMessage(
            {
                utterance: quastion,
                user_name: userName!,
                service_id: serviceId!
            },
            (response) => {
                onSuccess(response);
            }
            ,
            (status, detail) => {
                onFail(status, detail)
            }
        )
    }

    const chatSuccess = (question: string, userTime: Date, response: AxiosResponse<ChatMessageResponse, any> | undefined) => {
        if (response) {
            const returnText = response.data?.template?.outputs[0]?.simpleText?.text || '';
            addResultMessage(question, userTime, returnText);
            inputRef.current?.focus();
            scrollToBottom();
            setInputStatus(true)
            sessionStorage.setItem('sessionId', response.data.session_id);
        }
    }

    const chatFail = (question: string, userTime: Date, status: number, detail: string) => {
        addResultMessage(question, userTime, "오류가 발생 했습니다. 잠시 후 다시 시도해 주세요.");
        inputRef.current?.focus();
        scrollToBottom();
        setInputStatus(true)
        console.log("=========================== FAIL : " + status + " | " + detail);
    }

    const sendPdfChatMessage = async (quastion: string, onSuccess: (response: AxiosResponse<ChatMessageResponse, any> | undefined) => void, onFail: (status: number, detail: string) => void) => {
        let sessionId = sessionStorage.getItem('sessionId');
        const params = sessionId ? {
            utterance: quastion,
            user_name: userName!,
            service_id: serviceId!,
            knowledge_set_id: String(knowledge_set_id)!,
            sessionId: sessionId
        }
            :
            {
                utterance: quastion,
                user_name: userName!,
                service_id: serviceId!,
                knowledge_set_id: String(knowledge_set_id)!,
            }
        await sendFileChatMessage(
            params,
            (response) => {
                onSuccess(response);
            }
            ,
            (status, detail) => {
                onFail(status, detail)
            }
        )
    }

    const Bot = ({ text, time, isloading }: { text: string, time: Date, isloading: boolean | undefined }) => {
        return (
            <div className='chat_bot_layout'>
                <div className='bot_icon_layout'>
                    <BotIcon className='bot_icon' />&nbsp;&nbsp;DreamChat Bot
                </div>
                <div className='chat_bot_text'>
                    {
                        isloading ?
                            <div className='chat_textbox' style={{ display: 'flex' }} ><div>{text}</div>
                                <div className="loading_area">
                                    <div className="dot1"></div>
                                    <div className="dot2"></div>
                                    <div className="dot3"></div>
                                </div>
                            </div>
                            :
                            <div className='chat_textbox'><Markdown>{text}</Markdown></div>
                    }
                </div>
                <div className='pdf_chat_time'>{gridDateTime(time, 'hh:mm')}&nbsp;전송됨</div>
            </div>
        );
    }

    const User = ({ text, time }: { text: string, time: Date }) => {
        return (
            <div>
                <div className='chat_user_layout'>
                    <div className='chat_user_text'>
                        <div className='chat_textbox'>{text}</div>
                    </div>
                </div>
                <div className='chat_user_layout pdf_chat_time'>{gridDateTime(time, 'hh:mm')}&nbsp;전송됨</div>
            </div>
        );
    }
    return (
        <div className="external_layout">
            <div className={'external_chat_view ' + (isChatShow ? "external_chat_view_appear" : "")} style={{ display: isChatShow ? "flex" : "none" }}>
                <div className='chat_header_layout'>
                    <Close className='chat_close_button' onClick={() => { closeSession(); setIsChatShow(false) }} />
                    <div className='chat_title'>
                        <Logo />
                        <div className='chat_bold_text'>DreamChat</div>
                    </div>

                </div>
                <div className='chat_center_layout' ref={scrollRef}>
                    <Bot text={"안녕하세요. 무엇을 도와드릴까요?"} time={new Date()} isloading={false} />
                    {
                        messageList.map((data, index) => (
                            <div key={'chat_item_' + index}>
                                {
                                    data.isUser ?
                                        <User text={data.message} time={data.time} />
                                        :
                                        <Bot text={data.message} time={data.time} isloading={data.isloading} />
                                }
                            </div>
                        ))
                    }
                </div>
                <div className='chat_input_layout'>
                    <div className='chat_input_box'>
                        <input ref={inputRef}
                            placeholder={inputStatus ? '문서에 관해 무엇이든 물어보세요.' : '응답을 생성 중입니다.'}
                            disabled={!inputStatus} value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => activeEnter(e)} />
                        <SendIcon className='chat_input_send_icon' onClick={sendChatBotMessage} />
                    </div>
                    <div className='chat_copyright'>Powerd by DreamChat</div>
                </div>
            </div>
            <div className='external_chat_button' style={{ display: isChatShow ? "none" : "block" }} onClick={() => { setIsChatShow(true) }} >
                <Logo style={{ width: "44px", height: "44px" }} />
            </div>
        </div>
    );
}