import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { popupState } from '../atom/AtomState';
import { PopupProperty } from '../../popup/Popup';

export default function usePopup() {
    const setPopupList = useSetRecoilState(popupState);
    // 보여줄 팝업 추가 - 중첩 가능
    const showPopup = useCallback((data:PopupProperty) => {
        setPopupList((prev: PopupProperty[]) => {
            let newModalList: PopupProperty[] = [...prev];
            newModalList.push(data); // 추가
            return newModalList;
        });
    }, [setPopupList]);
    // 닫기 이벤트
    const dismissPopup = useCallback(() => {
        setPopupList((prev) => {
            let newModalList = [...prev];
            newModalList.pop(); // 마지막 항목 삭제
            return newModalList;
        });
    }, [setPopupList]);
    return {
        showPopup,
        dismissPopup
    };
}