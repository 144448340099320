import React, { useEffect, useState, useRef } from "react";

import {
    getBalance,
} from '../api/payment/PaymentApi';

import Top from './layout/Top';
import Left from './layout/Left';
import Right, { SummaryProps } from './layout/Right';
import { FileManager } from "./manager/FileManager";
import usePopup from "../hooks/popup/usePopup";
import './pdfchat.css';
import { PdfFileItem } from "../dto/Items";

function PdfChat() {
    const customer = JSON.parse(sessionStorage.getItem('customer') || "{}");
    const { showPopup } = usePopup();

    const fileManager = FileManager.getInstance();

    const [balance, setBalance] = useState(customer.balance);
    const [session_id, setSessionId] = useState<string | undefined>(undefined);
    const [knowledge_set_id, setKnowledgeSetId] = useState<number | undefined>(undefined);
    const [file_name, setFile_name] = useState<string | undefined>(undefined);

    // const file_id_ref = useRef<number | undefined>();

    const [file_status, setFile_status] = useState<number | undefined>(undefined);

    const [summary, setSummary] = useState<SummaryProps | undefined>(undefined);

    const [requestStatus, setRequestStatus] = useState<number>(0);

    const handleSessionSelect = (knowledge_set_id: number | undefined, session_id: string | undefined, file: PdfFileItem | undefined) => {
        setSessionId(session_id);
        fileManager.setSelectedFile(file)
        setKnowledgeSetId(knowledge_set_id)
    }

    const onPointChange = () => {
        getBalance({ customer_id: customer.id },
            (response) => {
                if (response?.data?.balance) {
                    setBalance(response.data.balance)
                    setRequestStatus(response.data.status)
                }
            },
            () => {

            }
        );
    }

    const onStatusChange = (fileId: number, status: number | undefined) => {
        if (fileManager.getSelectedFile()?.id === fileId) {
            setFile_status(status)
        }
    }

    useEffect(()=>{
        onPointChange();
    }, [])

    return (
        <div>
            <Top balance={balance} requestStatus={requestStatus} />
            <div className='pdf_content_view'>
                <Left className='v_section file_list' onSessionSelect={handleSessionSelect} setSummary={(id, maked_summary) =>{setSummary({file_id: id, text: (id == fileManager.getSelectedFile()?.id) ? maked_summary : ""})}} onPointChange={onPointChange}  onStatusChange={onStatusChange} />
                <Right className='v_section chat_view' knowledge_set_id={knowledge_set_id} session_id={session_id} summary={summary} onPointChange={onPointChange} />
            </div>
        </div>
    )
}
export default PdfChat;