import { AxiosResponse } from 'axios';
import APIService from '../APIService';
import {knowledgeFileItem} from '../../dto/Items'
import {FileUploadConfirmParams, GetKnowledgeFileListParams, DeleteKnowledgeFileParams, GetPdfFileListParams, GetPdfSummaryParams } from '../../dto/RequestParams'
import {KnowledgeFileListResponse, PdfFileListResponse} from '../../dto/ResponseData'

const prefix = 'knowledge_file/';

export async function knowledgeFileUpload (params: FormData, onUploadProgress: (event:ProgressEvent) => void, success: (res: AxiosResponse<knowledgeFileItem[], any> | undefined) => void, fail: (status: number, error: any) => void) {
    const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress
    }
    const response = await APIService.getInstance().post(prefix + `upload`, params, axiosConfig);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  knowledgeFileUploadConfirm (params: FileUploadConfirmParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'upload_confirm', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  knowledgePdfFileUploadConfirm (params: FileUploadConfirmParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'upload_confirm_pdf', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  makeKnowledgePdfFileSummary (params: FileUploadConfirmParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'summary_pdf', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getKnowledgePdfFileSummary (params: GetPdfSummaryParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_summary_pdf', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getKnowledgeFileList (params: GetKnowledgeFileListParams, success: (res: AxiosResponse<KnowledgeFileListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  getPdfFileList (params: GetPdfFileListParams, success: (res: AxiosResponse<PdfFileListResponse, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'get_pdf_list', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}

export async function  deleteKnowledgeFile (params: DeleteKnowledgeFileParams, success: (res: AxiosResponse<string, any> | undefined) => void, fail: (status: number, error: any) => void) {
    const response = await APIService.getInstance().post(prefix + 'delete', params);
    if (response?.status === 200) {
        success(response);
    }
    else fail(response?.status || 500, response?.data?.detail);
}
