import React, { useState, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from 'react-router';
import { RecoilRoot } from 'recoil';


import PdfChatLanding from './pdfchat/PdfChatLanding';
import PdfChatLogin from './pdfchat/PdfChatLogin';

import KakaoLogin from './pages/auth/KakaoLogin';
import NaverLogin from './pages/auth/NaverLogin';

import PdfChat from './pdfchat/PdfChat'

import ChatView from "./pages/external/ChatView";
import PdfChatView from "./pages/external/PdfChatView";

import Popup from './popup/Popup'


import './App.css';

function App() {
    let customer_id = sessionStorage.getItem('customer_id');
    // let service_type: string | null = sessionStorage.getItem('service_type');

    // const [currentServiceType, setCurrentServiceType] = useState<string>('');
    console.log("customer_id : " + customer_id);
    // console.log("currentServiceType : " + service_type);

    // const setServiceType = (type: string) => {
    //     setCurrentServiceType(type);
    // }
    // useLayoutEffect(() => {
    //     customer_id = sessionStorage.getItem('customer_id');
    //     // setCurrentServiceType(service_type || "");
    // }, [])
    return (
        <RecoilRoot>
            <BrowserRouter>
                <div className="app">
                    {
                        window.location.pathname.indexOf('/external/') === 0 ?
                            <Routes>
                                <Route path="/external/chatbot" element={<ChatView />} />
                                <Route path="/external/pdf_chat" element={<PdfChatView />} />
                            </Routes>
                            :
                            (
                                customer_id ?
                                    (
                                        <Routes>
                                            <Route path="/pdf_chat" element={<PdfChat />} />
                                            <Route path="/pdf_chat_login" element={<PdfChatLogin />} />
                                            {/* <Route path="/introduce" element={<PdfChatLanding />} /> */}
                                            <Route path="/*" element={<Navigate to='/pdf_chat' />} />
                                        </Routes>

                                    )
                                    :
                                    (
                                        <Routes>
                                            <Route path="/pdf_chat_login" element={<PdfChatLogin />} />
                                            <Route path="/kakao_login" element={<KakaoLogin />} />
                                            <Route path="/naver_login" element={<NaverLogin />} />
                                            <Route path="/pdf_chat" element={sessionStorage.getItem('customer_id') ? <PdfChat /> : <Navigate to='/introduce' />} />
                                            <Route path="/introduce" element={<PdfChatLanding />} />
                                            <Route path="/*" element={<Navigate to='/introduce' />} />
                                        </Routes>

                                    )
                            )
                    }
                </div>
            </BrowserRouter>
            <Popup />
        </RecoilRoot>
    );
}

export default App;