import React, { useState, useLayoutEffect, useEffect } from 'react';
import usePopup from "../../hooks/popup/usePopup";

import FileUploder from '../components/FileUploder'
import FileItem from '../components/FileItem'

import { knowledgeFileItem, PdfFileItem, FileErrorItem } from '../../dto/Items';

import '../pdfchat.css'
import { FileManager } from '../manager/FileManager';

interface LeftProps {
    className: string;
    onSessionSelect: (knowledge_set_id: number | undefined, session_id: string | undefined, file: PdfFileItem | undefined) => void;
    onStatusChange: (file_id: number, file_status: number | undefined) => void;
    setSummary: (file_id: number, text: string) => void;
    onPointChange: () => void;
}

function Left(props: LeftProps) {
    const { showPopup } = usePopup();
    const fileManager = FileManager.getInstance();

    const [fileList, setFileList] = useState<PdfFileItem[]>([]);
    const [canUpload, setCanupload] = useState(true);
    const [selectedFile, setSelectedFile] = useState<PdfFileItem | undefined>();

    const handleFileDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, file_id: number, knowledge_set_id: number) => {
        e.preventDefault();
        e.stopPropagation();
        showPopup({
            title: "확인",
            type: 'confirm',
            message: `해당 파일을 삭제하시겠습니까?`,
            confirm: () => {
                fileManager.deleteFile(knowledge_set_id).then(result => {
                    if (result) {
                        showPopup({ message: '삭제 되었습니다.' });
                        fileManager.refreshFileList().then(result => {
                            if (fileManager.instanceOfFileError(result)) {
                                showPopup({ message: result.detail ? result.detail.message : `Error(${result.status})\n실패 했습니다.` });
                            }
                            else {
                                setFileList(result);
                                const file = fileManager.getSelectedFile();
                                setSelectedFile(file);
                                if (fileManager.fileChangeListener) {
                                    fileManager.fileChangeListener(file);
                                }
                            }
                        });

                    }
                    else {
                        showPopup({ message:`파일 삭제를\n실패 했습니다.` });
                    }
                })
            }
        });
    }

    fileManager.setListChangeListener((list) => {
        setFileList(list);
        const file = fileManager.getSelectedFile();
        setSelectedFile(file);
        let check = true;
        for (let item of list) {
            if (item.status !== 0 && item.status !== 999 ) {
                setCanupload(false);
                check = false;
                break;
            }
        }
        if (check) {
            setCanupload(true);
        }
    })

    const handleFileSelect = (id: number | undefined, list?: PdfFileItem[]) => {
        var file;
        if (list) {
            file = list.find((element) => element.id === id);
        }
        else {
            file = fileList.find((element) => element.id === id);
        }
        if (!file) return;
        if (file.status === 999) {
            showPopup({ message: "에러가 발생한 파일입니다." })
        }
        else {
            fileManager.setSelectedFile(file);
            setSelectedFile(file);
            props.onSessionSelect(file.set_id, file.session_id, file);
        }
    }

    const onUploadFileGetSummary = (set_id: number, fileItem: knowledgeFileItem) => {
        fileManager.getSummery(set_id, fileItem).then(result => {
            if (fileManager.instanceOfFileError(result)) {
                showPopup({ message: result.detail ? result.detail.message : `Error(${result.status})\n실패 했습니다.` });
            }
            else {
                props.setSummary(result.file_id, result.summary);
            }
        });
    }

    const onUploaded = (fileItem: knowledgeFileItem) => {
        fileManager.refreshFileList().then(result => {
            if (fileManager.instanceOfFileError(result)) {
                showPopup({ message: result.detail ? result.detail.message : `Error(${result.status})\n실패 했습니다.` });
            }
            else {
                setFileList(result);
                const file = fileManager.findFileItem(fileItem.file_id);
                if (file) {
                    fileManager.setSelectedFile(file);
                    setSelectedFile(file);
                    handleFileSelect(file.id, result)
                    onUploadFileGetSummary(file.set_id, fileItem);
                }
            }
        })
    }

    const refreshFileList = () => {
        fileManager.refreshFileList().then(result => {
            if (fileManager.instanceOfFileError(result)) {
                showPopup({ message: result.detail ? result.detail.message : `Error(${result.status})\n실패 했습니다.` });
            }
            else {
                
                const file = fileManager.getSelectedFile();
                if (!selectedFile || selectedFile.id !== file?.id) {
                    setSelectedFile(file);
                    handleFileSelect(file?.id, result)
                }
                else {
                    setSelectedFile(undefined);
                    handleFileSelect(-1, [])
                }
            }
        });
    }

    useEffect(() => {
        refreshFileList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`${props.className}`}>
            <FileUploder onUploaded={onUploaded} uploadStatus={canUpload} />
            <div>
                {
                    fileList.map((item, index) => (
                        <FileItem key={index}
                            file={item}
                            isSelected={(selectedFile && selectedFile.id === item.id) || false}
                            onClick={(id) => { handleFileSelect(id) }}
                            onDeleteClick={(e, id, set_id) => { handleFileDelete(e, id, set_id) }}
                        />
                    ))
                }
            </div>
        </div>
    )
}
export default Left;