import { knowledgeFileUpload, makeKnowledgePdfFileSummary, knowledgePdfFileUploadConfirm } from '../../api/product/KnowledgeFileApi';
import { getProductList, deleteProduct } from '../../api/product/ProductApi';
import { getPdfFileList } from '../../api/product/KnowledgeFileApi';

import { FileIdItem, knowledgeFileItem, PdfFileItem, FileErrorItem, SummaryItem } from '../../dto/Items';
import FileItem from '../components/FileItem'


export class FileManager {
    private static instance: FileManager;
    customer_id = sessionStorage.getItem('customer_id');
    service_id = sessionStorage.getItem('service_id');
    fileList: PdfFileItem[] = [];
    selectedFile: PdfFileItem | undefined = undefined;
    fileChangeListener: null | ((file: PdfFileItem | undefined) => void) = null;
    listChangeListener: null | ((list: PdfFileItem[] | []) => void) = null;


    public static getInstance() {
        if (!FileManager.instance) {
            FileManager.instance = new FileManager();
        }
        return FileManager.instance;
    }



    instanceOfFileError(object: any): object is FileErrorItem {
        return 'detail' in object;
    }

    refreshFileList(): Promise<Array<PdfFileItem> | FileErrorItem> {
        return new Promise(resolve => {
            getProductList({ customer_id: this.customer_id, service_id: this.service_id },
                (response) => {
                    if (response?.data) {
                        let setList: number[] = [];
                        for (let item of response.data?.list) {
                            setList.push(item.id);
                        }
                        getPdfFileList({ knowledge_set_ids: setList },
                            (fileResponse) => {
                                if (fileResponse?.data) {
                                    this.setFileList(fileResponse.data?.list || []);
                                    if (this.fileList.length > 0) {
                                        let check = true;
                                        let setList: number[] = [];
                                        for (let file of this.fileList) {
                                            setList.push(file.set_id);
                                            if (file.status !== 0 && file.status !== 999) {
                                                check = false;
                                            }
                                        }
                                        if (!check) {
                                            setTimeout(() => {
                                                this.fileStatusCheck(setList);
                                            }, 5000);
                                        }
                                        if (this.selectedFile) {
                                            let fileItem = this.findFileItem(this.selectedFile.id);
                                            if (!fileItem) {
                                                this.setSelectedFile(fileResponse.data?.list[0]);
                                            }
                                        }
                                        else {
                                            this.setSelectedFile(fileResponse.data?.list[0]);
                                        }
                                    }
                                    else {
                                        this.setSelectedFile(undefined);
                                    }
                                }
                                resolve(this.fileList);
                            },
                            (status, detail) => {
                                this.setFileList([]);
                                resolve({ status, detail });
                            }
                        );
                    }
                },
                (status, detail) => {
                    this.setFileList([]);
                    resolve({ status, detail });
                }
            );
        });
    }

    findFileItem(fileId: number): PdfFileItem | undefined {
        for (let file of this.fileList) {
            if (file.id === fileId) {
                return file;
            }
        }
        return undefined
    }

    getSelectedFile(): PdfFileItem | undefined {
        return this.selectedFile;
    }

    setSelectedFile(file: PdfFileItem | undefined) {
        // if (!this.selectedFile || this.selectedFile?.id !== file?.id) {
            this.selectedFile = file;
            if (this.fileChangeListener) {
                this.fileChangeListener(file);
            }
        // }
    }

    getFileList(): PdfFileItem[] {
        return this.fileList;
    }

    setFileList(list: PdfFileItem[]) {
        this.fileList = list;
        if (this.listChangeListener) {
            this.listChangeListener(list);
        }

    }
    setListChangeListener(listChangeListener: (list: PdfFileItem[] | []) => void) {
        this.listChangeListener = listChangeListener;
    }

    setFileChangeListener(fileChangeListener: (file: PdfFileItem | undefined) => void) {
        this.fileChangeListener = fileChangeListener;
    }

    fileStatusCheck(ids: number[]) {
        if (ids.length > 0) {
            getPdfFileList({ knowledge_set_ids: ids },
                (fileResponse) => {
                    if (fileResponse?.data && fileResponse.data?.list.length > 0) {
                        this.setFileList(fileResponse.data?.list || []);
                        for (let item of fileResponse.data?.list) {
                            if (item.status !== 0 && item.status !== 999) {
                                setTimeout(() => {
                                    this.fileStatusCheck(ids);
                                }, 5000);
                                break;
                            }
                            if (this.selectedFile?.id === item.id) {
                                this.setSelectedFile(item);
                            }
                        }
                    }
                },
                (status, detail) => {
                })
        }
    }

    deleteFile(setId: number): Promise<boolean | FileErrorItem> {
        return new Promise(resolve => {
            deleteProduct(
                {
                    id: setId,
                    customer_id: this.customer_id!,
                    service_id: this.service_id!,
                },
                (response) => {
                    if (this.selectedFile?.set_id === setId) {
                        this.setSelectedFile(undefined);
                    }
                    resolve(true);
                },
                (status, detail) => {
                    resolve({status, detail});
                }
            )
        })
    }

    uploadfile(uploadfile: File, knowledgeSetId: number, onUploadProgress: (event: ProgressEvent) => void): Promise<knowledgeFileItem | FileErrorItem> {
        return new Promise(resolve => {
            const formData = new FormData();
            formData.append("id", String(knowledgeSetId));
            formData.append("files", uploadfile);

            knowledgeFileUpload(formData, onUploadProgress,
                (response) => {
                    if (response?.data) {
                        // setProgressPercent(100);
                        knowledgePdfFileUploadConfirm({
                            customer_id: this.customer_id!,
                            service_id: this.service_id!,
                            knowledge_set_id: knowledgeSetId,
                            file_list: response.data
                        },
                            () => {
                                // onUploadComplete();
                                resolve(response.data[0])
                            },
                            (confirmStatus, detail) => {
                                resolve({ status: confirmStatus, detail: detail });
                            }
                        );
                    }
                },
                (status, detail) => {
                    resolve({ status: status, detail: detail });
                });
        })
    }

    getSummery(set_id: number, fileItem: knowledgeFileItem): Promise<SummaryItem | FileErrorItem> {
        return new Promise(resolve => {
            makeKnowledgePdfFileSummary({
                customer_id: this.customer_id!,
                service_id: this.service_id!,
                knowledge_set_id: set_id,
                file_list: [fileItem]
            },
                (summaryResponse) => {
                    if (summaryResponse && fileItem.file_id === this.selectedFile?.id) {
                        resolve({ file_id: fileItem.file_id, summary: summaryResponse.data[0] });
                    }
                },
                (status, detail) => {
                    resolve({ status, detail });
                }
            )
        })
    }
}