// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.point_approval_layout {
    width: 240px;
    height: fit-content;
    padding: 20px;
    border-radius: 8px;
    background-color: white;
    border: solid 1px #CCCCCC;
}
.point_approval_popup_input_layout {
    text-align: left;
    margin-top: 20px;
}
.point_decline_title {
    font-size: 18px;
    margin-bottom: 8px;
}
.point_approval_popup_title {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}
.point_approval_select {
    font-size: 20px;
    font-weight: 500;
}
.popup_decline {
    background: #ef233c;
    font-weight: 600;
    color: white;
}
.popup_edit_area {
    border: solid 1px #CCCCCC;
    resize: none;
    width: 230px;
    padding: 4px;
    height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/popup/point/pointPopup.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,uBAAuB;IACvB,yBAAyB;AAC7B;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".point_approval_layout {\n    width: 240px;\n    height: fit-content;\n    padding: 20px;\n    border-radius: 8px;\n    background-color: white;\n    border: solid 1px #CCCCCC;\n}\n.point_approval_popup_input_layout {\n    text-align: left;\n    margin-top: 20px;\n}\n.point_decline_title {\n    font-size: 18px;\n    margin-bottom: 8px;\n}\n.point_approval_popup_title {\n    display: flex;\n    justify-content: space-between;\n    font-size: 20px;\n    font-weight: 500;\n    margin-bottom: 15px;\n}\n.point_approval_select {\n    font-size: 20px;\n    font-weight: 500;\n}\n.popup_decline {\n    background: #ef233c;\n    font-weight: 600;\n    color: white;\n}\n.popup_edit_area {\n    border: solid 1px #CCCCCC;\n    resize: none;\n    width: 230px;\n    padding: 4px;\n    height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
