import React, { useEffect, useState, useRef } from "react";
import Markdown from 'react-markdown'
import { pdfjs, Document, Page, Thumbnail } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import FileChat from "../components/FileChat";
import { ConversationKnowledgeItem, KnowledgeItem } from '../../dto/Items';

import { ReactComponent as Send } from '../../assets/pdf_chat/send.svg';
import '../pdfchat.css'
import { FileManager } from "../manager/FileManager";
import Loading from "../../components/iconify/loading";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

export interface SummaryProps {
    file_id: number;
    text: string;
}

interface RightProps {
    className: string;
    session_id: string | undefined;
    knowledge_set_id: number | undefined;
    summary: SummaryProps | undefined;
    onPointChange: () => void;
}
function Right(props: RightProps) {
    const [isReferenceOpen, setIsReferenceOpen] = useState(false);
    const [knowledgeList, setKnowledgeList] = useState<ConversationKnowledgeItem[] | undefined>([]);
    const [selectedKnowledgeSetId, setSelectedKnowledgeSetId] = useState<number>(-1);
    const [selectedFileStatus, setSelectedFileStatus] = useState<number>(0);
    const [selectedKnowledgeFileName, setSelectedKnowledgeFileName] = useState<string>("");


    const [pdfPageCount, setPdfPageCount] = useState<number>(-1);

    const fileManager = FileManager.getInstance();

    const pageRef = useRef<HTMLDivElement[]>([]);
    const thumbRef = useRef<HTMLDivElement[]>([]);

    fileManager.setFileChangeListener((file) => {
        if (file) {
            setSelectedFileStatus(file.status);
            if (file.set_id) {
                setSelectedKnowledgeSetId(file.set_id);
                setSelectedKnowledgeFileName(file.name);
                setKnowledgeList([]);
            }
            if (file.session_id) {
                sessionStorage.setItem("currentSessionId", file.session_id);
            }
            else {
                sessionStorage.removeItem("currentSessionId");
            }
        }
        else {
            setSelectedKnowledgeSetId(-1);
            setSelectedKnowledgeFileName("");
            setKnowledgeList([]);
            sessionStorage.removeItem("currentSessionId");
        }
    })

    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
        setPdfPageCount(numPages);
    }

    const renderThumbnail = () => {
        let result = [];
        if (pdfPageCount > 0) {
            for (let i = 0; i < pdfPageCount; i++) {
                const check = knowledgeList?.findIndex((item) => item.metadata.page === (i + 1));
                result.push(
                    <div className={"pdf_viewer_item"} style={{ border: check != undefined && check >= 0 ? "solid 2px #FF0000" : "solid 1px #CCCCCC" }} ref={el => thumbRef.current[i] = el as HTMLDivElement}>
                        <Thumbnail width={100} pageNumber={i + 1} />
                    </div>
                )
            }
        }
        return result;
    }
    const renderPage = () => {
        let result = [];
        if (pdfPageCount > 0) {
            for (let i = 0; i < pdfPageCount; i++) {
                result.push(
                    <div className={"pdf_viewer_item"} ref={el => pageRef.current[i] = el as HTMLDivElement}>
                        <Page width={600} pageNumber={i + 1} loading={<Loading width='80px'></Loading>} >
                            {
                                knowledgeList && knowledgeList.length > 0 &&

                                knowledgeList.map((item, index) => {
                                    if (item.metadata.page === (i + 1)) {
                                        const left = (item.metadata.coordinates[0].x * 100) + "%";
                                        const top = (item.metadata.coordinates[0].y * 100) + "%";
                                        const width = ((item.metadata.coordinates[1].x - item.metadata.coordinates[0].x) * 100) + "%";
                                        const height = ((item.metadata.coordinates[2].y - item.metadata.coordinates[0].y) * 100) + "%";
                                        return <div key={"page_" + i + "_" + index} className={"item_" + (item.tree_id + 1)} style={{ position: "absolute", left: left, top: top, width: width, height: height, opacity: 0.5 }} />
                                    }
                                })
                            }

                        </Page>
                    </div>
                );
            }
        }
        return result;
    }

    const onKnowledgeClick = (knowledge?: ConversationKnowledgeItem[]) => {
        setIsReferenceOpen(true);
        if (knowledge && knowledge.length > 0) {
            const pageNum = knowledge[0].metadata.page - 1;
            thumbRef.current[pageNum].scrollIntoView();
            pageRef.current[pageNum].scrollIntoView();
            setKnowledgeList(knowledge);
        }
    }

    useEffect(() => {
        if (!knowledgeList || knowledgeList?.length === 0) {
            setIsReferenceOpen(false)
        }
    }, [knowledgeList]);

    return (
        <div className={`${props.className}`}>
            <div className='v_section chat_layout'>
                {
                    selectedKnowledgeSetId > 0 ?
                        <FileChat
                            knowledge_set_id={selectedKnowledgeSetId}
                            selected_file_status={selectedFileStatus}
                            onKnowledgeClick={onKnowledgeClick}
                            onPointChange={props.onPointChange}
                            makedSummary={props.summary?.text}
                            isReferenceOpen={isReferenceOpen} />
                        :
                        <div className="pdf_chat_help">
                            <div>
                                <div className="pdf_chat_prompt">
                                    문서를 업로드하고 AI에게 무엇이든 물어보세요.
                                </div>
                                <div className="pdf_chat_input_box pdf_chat_input_box_help">
                                    <input className="pdf_chat_input" placeholder="문서에 관해 무엇이든 물어보세요." disabled />
                                    <div className="pdf_send_box">
                                        <Send className="pdf_send_button" />
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>
            {
                selectedKnowledgeSetId > 0 &&
                <div className={`v_section referance_layout `} >
                    <Document file={`${process.env.REACT_APP_BASE_URL}/dreamx/knowledge_file/${selectedKnowledgeSetId}/${selectedKnowledgeFileName}`} onLoadSuccess={onDocumentLoadSuccess} 
                    loading={<Loading width='80px'></Loading>}
                    >
                        <div className="pdf_viewer_layout">
                            <div className="pdf_viewer_page_layout">
                                {renderPage()}
                            </div>
                            <div className="pdf_viewer_thumbnail_layout" >
                                {renderThumbnail()}
                            </div>
                        </div>
                    </Document>
                </div>
            }
        </div>
    )
}
export default Right;